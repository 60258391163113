<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light hidden-pc p-0">
      <div
        class="container-fluid"
        style="
          z-index: 99;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: #110000;
        "
      >
        <div
          class="collapse navbar-collapse d-flex justify-content-between m-2"
          style="color: #fff"
          id="navbarExample01"
        >
          <h4 class="mb-2 text-black text-center">
            <b>© 2021</b>
          </h4>
          <div class="collapse navbar-collapse" id="navbarNavDropdown"></div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  computed: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
</style>
